<template>
    <div class="indicator-answer" :class="{ hidden: indicator.hidden, 'is-duplicating': isDuplicating }">
        <div class="name">
            <div class="checkbox-container">
                <q-checkbox
                    class="duplicate-checkbox"
                    :value="selected"
                    @input="handleDuplicateIndicator"
                    :disabled="checkboxDisabled"
                ></q-checkbox>
            </div>
            <span v-html="markedName" class="animated-line-through" :class="indicator.hidden ? 'show-line' : 'hide-line'"></span>
            <q-tooltip v-if="indicator.description" position="top">
                <template #tooltip>
                    <p>{{ indicator.description }}</p>
                </template>
                <q-icon class="info" type="info-circle" width="14px"></q-icon
            ></q-tooltip>
        </div>

        <div class="answer" v-if="isEditing">
            <div class="input">
                <span v-if="['text','file'].includes(indicator.answerType)">
                    <q-text
                        v-model="answer"
                        :disabled="disabled"
                        size="small"
                        rows="1"
                        maxlength="0"
                        @blur="save"
                        class="q-text"
                        autofocus
                        data-testid="test-textfield"
                    ></q-text>
                </span>
                <span v-else-if="['dropdown','radio'].includes(indicator.answerType)">
                    <q-dropdown
                        v-model="answer"
                        :options="indicator.answerValues"
                        class="dropdown-input"
                        size="small"
                        :disabled="disabled"
                        @input="saveDropdown"
                        @blur="cancel"
                        data-testid="test-dropdown"
                        ref="dropdown"
                        autocomplete
                        autofocus
                    ></q-dropdown>
                </span>
                <span v-else-if="['dropdown-multiple','checkbox'].includes(indicator.answerType)">
                    <q-dropdown
                        v-model="answer"
                        :options="indicator.answerValues"
                        :disabled="disabled"
                        class="dropdown-input"
                        size="small"
                        data-testid="test-dropdown-multiple"
                        ref="dropdown"
                        addNullValue
                        nullLabel="Geen"
                        multiple
                        autocomplete
                        autofocus
                        @blur="save"
                    ></q-dropdown>
                </span>
                <span v-else-if="indicator.answerType === 'date'">
                    <el-date-picker
                        class="datepicker"
                        v-model="answer"
                        :disabled="disabled"
                        type="date"
                        size="small"
                        :default-value="new Date()"
                        value-format="timestamp"
                        @blur="save"
                        placeholder="Kies een datum"
                        ref="focus"
                    >
                    </el-date-picker>
                </span>
                <span v-else-if="indicator.answerType === 'organisation'">
                    <q-select-organisation
                        v-model="answer"
                        :disabled="disabled"
                        size="small"
                        :type="indicator.masterId"
                        data-testid="test-organisation"
                        @input="save"
                    ></q-select-organisation>
                </span>
                <span v-else-if="indicator.answerType === 'number'">
                    <q-input 
                        v-model="answer"
                        labelPosition="inside"
                        :inputType="indicator.displayType === 'currency' ? 'currency' : 'number'"
                        size="small"
                        saveOnEnter
                        autofocus
                        :disabled="disabled"
                        @blur="save"
                        data-testid="test-textfield"
                        :unit="indicator.unit"
                        class="remove-padding"
                    ></q-input>
                </span>
                <div v-else-if="indicator.answerType === 'upload'">
                    <q-text
                        v-model="answer.text"
                        :disabled="disabled"
                        size="small"
                        rows="2"
                        maxlength="0"
                        autofocus
                        data-testid="test-textfield"
                    ></q-text>
                    <input id="file-upload-input" @input="uploadFile" type="file" style="display: none" />
                    <div v-if="answer.documentPath" class="file">
                        <span><b>{{ answer.documentName }}</b></span>
                        <q-icon type="cancel" width="14" height="14" class="close-icon" @click="handleDeleteFile"></q-icon>
                    </div>
                    <label v-else class="file upload" for="file-upload-input">
                        <span>Bijlage uploaden</span>
                        <q-icon type="cancel" width="12" height="12" class="plus-icon"></q-icon>
                    </label>
                    <div class="buttons">
                        <q-button size="small" variation="blank" @click="cancel" :disabled="uploading">Annuleren</q-button>
                        <q-button size="small" @click="save" :disabled="uploading">Opslaan</q-button>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="value">
            <div class="left">
                <span
                    v-if="displayAnswer"
                    :class="['display', !showEditButton.status || disabled ? 'disabled' : null]"
                >
                    <div v-if="indicator.answerType === 'dropdown-multiple'" class="answer-chip-wrapper">
                        <div v-for="answer in dropdownMultipleAnswers" :key="answer.value" class="answer-chip animated-line-through" :class="indicator.hidden ? 'show-line' : 'hide-line'">
                            {{ answer.label }}
                        </div>
                    </div>
                    <div v-else>
                        <p class="animated-line-through" :class="[collapsedAnswer ? 'collapsed' : 'opened', indicator.hidden ? 'show-line' : 'hide-line']" @click="collapsedAnswer = !collapsedAnswer" v-html="markedAnswer"></p>
                    </div>
                </span>
                <q-button
                    v-else-if="showEditButton.status && canEditIndicator"
                    :disabled="disabled"
                    variation="light"
                    size="xsmall"
                    @click="edit"
                    :data-testid="'edit-' + $t(indicator.answerType)"
                >
                    Invullen
                </q-button>
                <span v-else class="no-value">Niet ingevuld</span>
            </div>
            <div class="right">
                <q-button
                    v-if="displayAnswer && showEditButton.status && canEditIndicator"
                    :disabled="disabled || isDuplicating"
                    class="edit"
                    variation="light"
                    size="xsmall"
                    @click="edit"
                    :data-testid="'edit-' + $t(indicator.answerType)"
                    >Wijzigen</q-button
                >
                <q-tooltip v-else-if="(!showEditButton.status || !canEditIndicator) || disabled" position="top">
                    <template #tooltip>
                        <p>{{ showEditButton.message }}</p>
                    </template>
                    <q-icon class="info" type="lock-closed" color="grey" width="10px"></q-icon
                ></q-tooltip>
                <q-options
                    v-if="!isDuplicating && !isEditing && options.length > 0"
                    class="delete-indicator-option"
                    color="#C4C4C4"
                    position="left"
                    :options="options"
                    @input="handleOptionSelected"
                >
                </q-options>
            </div>
        </div>

        <q-popup v-if="showIndicatorDeleteModal" width="40%">
            <indicator-delete-modal
                @close="closeIndicatorDeleteModal"
                @deleteIndicator="deleteIndicatorFromProject"
                :loadingDeleteIndicator="loadingDeleteIndicator"
            >
            </indicator-delete-modal>
        </q-popup>
    </div>
</template>

<script>
import { ORGANISATION, GET_DOCUMENT_URL } from '@/graphql/queries';
import { SAVE_VALUE_VALUE, REMOVE_ALL_INDICATORS } from '@/graphql/mutations';
import { extractError, getMoney, getDate, isValidAnswer } from '@/assets/js/utils';
import _ from 'lodash';

import IndicatorDeleteModal from './IndicatorDeleteModal.vue';

export default {
    name: 'IndicatorAnswer',
    components: {
        IndicatorDeleteModal,
    },
    props: {
        project: {
            type: Object
        },
        indicator: {
            type: Object,
            required: true,
        },
        isEditing: {
            type: Boolean,
        },
        isDuplicating: {
            type: Boolean,
            default: false,
        },
        selected: {
            type: Boolean,
            default: false,
        },
        checkboxDisabled: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false
        },
        search: {
            type: String,
            default: ''
        },
        // boolean to show the option to hide the context
        showVisibilityOption: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loadingDeleteIndicator: false,
            showIndicatorDeleteModal: false,
            answer: null,
            cachedAnswer: null,
            displayAnswer: '',
            duplicateCheckbox: false,
            isRequiredIndicator: false,
            collapsedAnswer: true,
            uploading: false,
            cachedAnswer: null
        };
    },
    methods: {
        deleteIndicatorFromProject() {
            this.loadingDeleteIndicator = true;
            const projectId = this.$route.params.id;
            const indicatorIds = [this.indicator.id];

            this.$apollo
                .mutate({
                    mutation: REMOVE_ALL_INDICATORS,
                    variables: {
                        id: projectId,
                        indicatorIds: indicatorIds,
                    },
                })
                .then((result) => {
                    this.loadingDeleteIndicator = false;
                    this.showIndicatorDeleteModal = false;
                    this.$emit('removeIndicator', this.indicator);
                    this.$store.commit('notify', { type: 'success', message: 'Kenmerk is succesvol verwijderd uit dit project' })
                })
                .catch((error) => {
                    this.loadingDeleteIndicator = false;
                    this.showIndicatorDeleteModal = false;
                    this.$store.commit('notify', { type: 'danger', message: 'Er is iets fout gegaan tijdens het verwijderen van het kenmerk' })
                });
        },
        handleOptionSelected(option) {
            if(option.name === 'Kenmerk verwijderen') this.showIndicatorDeleteModal = true;
            else if(option.name.includes('verbergen') ||
                option.name.includes('tonen')) this.$emit('toggleVisibility', this.indicator);
            else if(option.name === 'Bestand downloaden') this.handleDownloadFile();
            else if(
                option.name.includes('verbergen') ||
                option.name.includes('tonen')
            ) this.$emit('toggleVisibility', this.indicator);
        },
        handleDownloadFile() {
            const { documentPath } = this.indicator.answer;

            this.$apollo.mutate({
                mutation: GET_DOCUMENT_URL,
                variables: {
                    documentPath
                }
            })
            .then(result => {
                window.open(result.data.document_getUrl, '_blank')
            })
            .catch(error => {
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het klaarzetten van de download' })
            })
        },
        closeIndicatorDeleteModal() {
            this.showIndicatorDeleteModal = false;
        },
        save() {
            const indicator = _.clone(this.indicator);
            const organisationId = this.$store.getters.getCurrentOrganisation.id;

            let answer = this.answer;
            if (indicator.answerType === 'date') {
                answer = this.answer ? new Date(this.answer).getTime() : null;

                if (
                    (indicator.masterId === 'project-end-date' || indicator.masterId === 'project-start-date') &&
                    answer &&
                    this.compareStartEndDates(indicator, answer)
                )
                    return;
            } else if (this.indicator.answerType === 'number') answer = parseFloat(answer);

            const different = this.checkDifferentAnswer(answer, indicator.answer);
            if (!different) return this.cancel();

            this.cancel();
            this.updateDisplayAnswer();

            const projectJwt = this.getProjectJwt();

            indicator.answer = answer;
            this.$emit('startLoading', indicator);

            this.$apollo
                .mutate({
                    mutation: SAVE_VALUE_VALUE,
                    variables: {
                        componentId: indicator.componentId,
                        responseId: this.$parent.project.referencesResponseId,
                        formId: this.$parent.project.referencesId,
                        indicatorId: indicator.id,
                        value: answer,
                        organisationId,
                        projectJwt
                    },
                    context: {
                        headers: {
                            Authorization: `Bearer ${this.$store.getters.getJwt}`,
                        },
                    },
                    fetchPolicy: 'no-cache',
                })
                .then((response) => {
                    if (response.data.value_setValue) {
                        this.indicator.answer = response.data.value_setValue.value;
                        this.answer = _.clone(this.indicator.answer);
                        this.cachedAnswer = _.clone(this.indicator.answer);

                        this.updateDisplayAnswer();

                        this.$emit('saveIndicator', { value: response.data.value_setValue, indicator: this.indicator });
                        this.$store.commit('notify', {
                            type: 'success',
                            message: 'De wijzigingen in de gegevens zijn doorgevoerd',
                        });
                    }
                })
                .catch((err) => {
                    this.answer = _.clone(this.cachedAnswer);
                    this.indicator.answer = this.answer;
                    this.updateDisplayAnswer();
                    this.$store.commit('notify', extractError(err));
                    this.$emit('stopLoading', this.indicator);
                });
        },
        getProjectJwt() {
            const project = JSON.parse(localStorage.getItem('projectJwt'));
            if(!project || project.id !== this.$route.params.id) return null
            return project.jwt
        },
        checkDifferentAnswer(newAnswer, oldAnswer) {
            if (!isValidAnswer(oldAnswer)) return true;
            if (this.indicator.answerType !== 'dropdown-multiple') return oldAnswer !== newAnswer;
            if (newAnswer.length !== oldAnswer.length) return true;
            return newAnswer.filter((answer) => oldAnswer.includes(answer)).length !== oldAnswer.length;
        },
        /**
         * Returns TRUE if not valid start- / enddate.
         */
        compareStartEndDates(indicator, answer) {
            const allIndicators = this.$parent.project.indicators;
            const types = ['project-start-date', 'project-end-date'];
            const indexOfType = types.indexOf(indicator.masterId);
            const otherIndicator = allIndicators.filter((indicator) => {
                return indicator.masterId === types[types.length - 1 - indexOfType];
            });
            if (!otherIndicator[0].answer) return;
            if (indexOfType) {
                if (answer < otherIndicator[0].answer) {
                    this.$store.commit('notify', {
                        type: 'warning',
                        message: 'Einddatum mag niet eerder zijn dan de startdatum',
                    });
                    // reset answer to latest confirmed value
                    this.answer = this.indicator.answer;
                    return true;
                }
            } else {
                if (answer > otherIndicator[0].answer) {
                    this.$store.commit('notify', {
                        type: 'warning',
                        message: 'Startdatum mag niet later zijn dan de einddatum',
                    });
                    // reset answer to latest confirmed value
                    this.answer = this.indicator.answer;
                    return true;
                }
            }
        },
        cancel() {
            this.$emit('cancel');
            if(this.indicator.answerType === 'upload') this.answer = _.clone(this.cachedAnswer);
        },
        edit() {
            this.$emit('edit', this.indicator);
            setTimeout(() => {
                if (this.$refs.focus) this.$refs.focus.focus();
            }, 10);
        },
        updateDisplayAnswer() {
            if (!isValidAnswer(this.answer)) {
                this.displayAnswer = '';
                return '';
            }

            if (this.indicator.answerType === 'date') {
                this.displayAnswer = getDate(this.answer);
            } else if (['dropdown', 'radio'].includes(this.indicator.answerType)) {
                const option = this.indicator.answerValues.find((value) => value.value === this.answer);
                if (option) this.displayAnswer = option.label;
            } else if (['dropdown-multiple', 'checkbox'].includes(this.indicator.answerType)) {
                if(typeof this.answer === 'boolean') return this.displayAnswer = this.answer ? 'Ja' : 'Nee'

                const answered = this.answer.map((answer) => {
                    return this.indicator.answerValues.find((value) => value.value === answer).label;
                });

                if (answered.length > 1) {
                    this.displayAnswer = answered.join(', ');
                } else this.displayAnswer = answered[0];
            } else if (this.indicator.answerType === 'organisation') {
                const vm = this;
                this.getPickedOrganisation().then((organisation) => {
                    if (!organisation) vm.displayAnswer = '';
                    else
                        vm.displayAnswer = vm.indicator.masterId === 'contractor-branch'
                            ? `${organisation.address.line1}, ${organisation.address.city}`
                            : organisation.name;
                });
            } else if (this.indicator.answerType === 'number') {
                switch(this.indicator.displayType) {
                    case'currency':
                        this.displayAnswer = getMoney(this.answer);
                        break
                    case'numberUnit':
                        if(this.indicator.unit === '€') this.displayAnswer = getMoney(this.answer);
                        else this.displayAnswer = `${this.answer} ${this.indicator.unit}`;
                        break
                    default:
                        this.displayAnswer = this.answer;
                        break
                }
            } else if (this.indicator.answerType === 'upload') {
                const { documentName, text } = this.answer;
                this.displayAnswer = text
                if(documentName) this.displayAnswer += `<br><br><b>${documentName}</b>`;
            } else this.displayAnswer = this.answer;
        },
        getPickedOrganisation() {
            const id = this.indicator.answer;

            if (!id || !this.$apollo) return;

            return this.$apollo
                .query({
                    query: ORGANISATION,
                    variables: {
                        organisationId: id,
                    },
                })
                .then((result) => {
                    return result.data.organisation;
                })
                .catch((err) => {
                    this.displayAnswer = '';
                });
        },
        saveDropdown(value) {
            if (value !== null && value !== undefined) this.save();
        },
        handleDuplicateIndicator(checked) {
            this.$emit('checkboxUpdated', checked);
        },
        markName(name) {
            if(!this.search) return name
            const re = new RegExp(this.search, 'i');
            const match = name.toString().match(re);
            const marked = name.toString().replace(re, `<span style="background-color: #F4B976;border-radius:3px;">${match}</span>`);
            return marked
        },
        uploadFile(event) {
            const UPLOAD_ENDPOINT = `${process.env.UPLOAD_URL}/letter_documents`;
            const authorizationHeader = `Bearer ${this.$store.getters.getJwt}`;

            const file = event.target.files[0];
            const organisationId = this.$store.getters.getCurrentOrganisation.id;
            const userId = this.$store.getters.getUser.id;

            const formData = new FormData();
            formData.append('file', file);
            formData.append('organisationId', organisationId);
            formData.append('userId', userId);

            this.uploading = true;

            this.$http
                .post(UPLOAD_ENDPOINT, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: authorizationHeader,
                        'application-context': 'application-context'
                    }
                })
                .then(response => {
                    this.uploading = false;
                    const documentName = response.data.name
                    const documentPath = response.data.documentPath;
                    this.answer.documentName = documentName;
                    this.answer.documentPath = documentPath;
                })
                .catch((error) => {
                    this.$store.commit('message', { type: 'danger', message: 'Er ging iets fout tijdens het uploaden' })
                });
        },
        handleDeleteFile() {
            this.answer.documentName = '';
            this.answer.documentPath = '';
        }
    },
    computed: {
        dropdownMultipleAnswers() {
            if (this.indicator.answerType !== 'dropdown-multiple') return [];
            return this.answer.map((answer) => {
                const { label, value } = this.indicator.answerValues.find((value) => value.value === answer);
                return {
                    label,
                    value,
                };
            });
        },
        showEditButton: function () {
            const answer = this.indicator.answer;

            if (this.indicator.masterId === 'contractor-branch' && !this.$parent.contractorReference?.answer && this.canEditIndicator)
                return {
                    status: false,
                    message: 'Opdrachtnemer moet eerst gekozen worden',
                };

            return {
                status: !(
                    (
                        ['client', 'contractor', 'contractor-branch'].includes(this.indicator.masterId)
                        &&
                        ['amsterdam-pricing'].includes(this.indicator.id)
                    ) &&
                    isValidAnswer(answer)
                ),
                message: 'U mag dit veld niet aanpassen'
            };
        },
        selectOrganisationType: function () {
            switch (this.indicator.masterId) {
                case 'client':
                    return 'main';
                    break;
                case 'contractor-branch':
                    return 'branch';
                default:
                    break;
            }
        },
        canEditIndicator: function () {
            return this.$parent.canEditIndicator(this.indicator);
        },
        markedName: function() {
            let name = this.$t(this.indicator.name);
            let suffix = this.indicator.required ?  `<span style="color:#00a1ae">*</span>` : '';
            if(!this.search) return name + suffix
            return this.markName(name) + suffix
        },
        markedAnswer: function() {
            const answer = this.displayAnswer;
            if(!this.search) return answer
            return this.markName(answer)
        },
        options: function() {
            const label = this.indicator.hidden ? 'tonen' : 'verbergen';
            const options = [
                {
                    name: 'Bestand downloaden',
                    show: this.indicator.answerType === 'upload' && this.indicator.answer?.documentPath && this.canEditIndicator
                },
                { 
                    name: `Kenmerk ${label}`,
                    show: this.showVisibilityOption
                },
                {
                    name: 'Kenmerk verwijderen',
                    color: 'red',
                    show: !this.isRequiredIndicator && this.canEditIndicator
                },
            ];

            const filtersToShow = options.filter(option => option.show)

            return filtersToShow
        }
    },
    watch: {
        indicator: function () {
            if (isValidAnswer(this.indicator.answer)) this.answer = _.clone(this.indicator.answer);
            this.updateDisplayAnswer();
        }
    },
    created() {
        if(this.indicator.answerType === 'date' && !this.indicator.answer) this.answer = null;
        else if(this.indicator.answerType === 'upload' && !this.indicator.answer) {
            this.answer = {
                documentName: '',
                documentPath: '',
                text: ''
            };
        }
        else this.answer = _.clone(this.indicator.answer);

        if (this.indicator.answerType == 'organisation') this.getPickedOrganisation();

        this.updateDisplayAnswer();

        this.cachedAnswer = _.clone(this.answer);

        const requiredComponentIds = ['component-client','component-contractor','component-contractor-branch','component-project-start-date','component-project-end-date','component-budget','component-crow-type','component-crow-project-type','component-crow-contractform','component-crow-procedure','component-crow-assignment-criteria','component-invoiced-sum','component-form-version','component-durability', 'component-framework-agreement', 'component-amsterdam-pricing', 'component-discussed-psu', 'component-collaboration-contractor'];
        this.isRequiredIndicator = requiredComponentIds.includes(this.indicator.componentId);
    },
};
</script>

<style lang="scss" scoped>
@import '../../../components/qds/assets/style/_variables.scss';
@import '../../../components/qds/assets/style/fonts/fonts.css';

.animated-line-through {
    background-image: linear-gradient($color-grey-9, $color-grey-7);
    background-repeat: no-repeat;
    background-position: left center;
    transition: background-size .4s ease-out;

    &.show-line {
        color: $color-grey-7;
        background-size: 100% 1px;
    }

    &.hide-line {
        background-size: 0% 1px;
        background-position: right center;
    }
}

.remove-padding {
    padding: 0 !important;
}

.el-date-table td.current span {
    background-color: #ff4040 !important;
}

.datepicker {
    width: 100%;
}

.indicator-answer:hover {
    background-color: lighten($color-grey-3, 5%);
    position: relative;

    .name .tooltip {
        opacity: 1;
        scale: 1;
        pointer-events: all;
        transition: scale .3s cubic-bezier(.51,1.6,.45,1.08) .2s;
    }

    .value {
        .right {
            .edit {
                display: inherit;
            }
            .tooltip {
                display: inherit;
            }
        }
    }
}

.indicator-answer {
    position: relative;
    --padding-inline: 12px;
    display: flex;

    margin-left: calc(var(--padding-inline) * -1);
    width: 100%;

    align-items: center;

    min-height: 35px;

    border-radius: 4px;
    padding: 4px var(--padding-inline);

    &.hidden {
        background-color: lighten($color-grey-3, 5%);
    }

    &:not(:hover) .delete-indicator-option {
        opacity: 0;
    }

    &.is-duplicating {
        .duplicate-checkbox {
            opacity: 1;
            pointer-events: all;
        }

        .name {
            margin-left: 28px;
        }
    }

    .duplicate-checkbox {
        position: absolute;
        top: 50%;
        translate: 0 -50%;
        margin-left: -28px;
        opacity: 0;
        pointer-events: none;
        transition: 0.2s var(--animation-stagger);
    }

    .name {
        min-width: 45%;
        max-width: 45%;
        flex-grow: 1;
        overflow: hidden;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        transition: margin 0.2s var(--animation-stagger);

        
        span {
            max-width: 90%;
        }

        .required {
            color: $color-primary;
        }

        .tooltip {
            margin-left: 2px;
            opacity: 0;
            scale: 0;
            translate: 0 4px;
            pointer-events: none;
            transition: .2s ease;
        }
    }

    .answer {
        min-width: 55%;
        max-width: 55%;
        font-weight: normal;
        font-size: 13px;
        line-height: 22px;

        font-size: $size-s;

        width: 100%;

        display: flex;

        .q-text {
            margin-bottom: -6px;
        }
        
        .input {
            width: 100%;

            .buttons {
                display: flex;
                justify-content: space-between;
            }
            .file {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 4px;
                border-radius: 4px;
                margin-bottom: 8px;

                &.upload {
                    padding: 4px 8px;
                    cursor: pointer;

                    &:hover {
                        background: $color-grey-3;
                    }

                    .plus-icon {
                        rotate: 45deg;
                    }
                }

                span {
                    font-size: 13px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .close-icon {
                    color: red;
                    cursor: pointer;
                }
            }
        }

    }

    .value {
        display: flex;
        justify-content: space-between;
        align-items: center;

        min-width: 55%;
        max-width: 55%;
        font-weight: normal;
        font-size: 13px;
        line-height: 22px;

        .left {
            width: 100%;
            
            .disabled {
                color: lighten($color-grey-7, 20%);
            }
    
            .no-value {
                color: $color-grey-5;
            }

            p {
                font-size: 14px;
                line-height: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                white-space: pre-wrap;
                overflow-wrap: break-word;
                cursor: pointer;
                width: fit-content;

                &.collapsed {
                    -webkit-line-clamp: 2;
                }
                &.opened {
                    -webkit-line-clamp: unset;
                }
            }
        }

        .right {
            position: absolute;
            right: 11px;
            display: flex;
            align-items: center;
            gap: 4px;

            .edit {
                display: none;
                background-color: #eef0f1;
            }
    
            .tooltip {
                display: none;
            }

            .delete-indicator-option {
                margin-right: -3px;
            }
        }

    }


    .answer-chip-wrapper {
        margin-left: -6px;
        padding: 4px 0px;
        
        .answer-chip {
            display: inline-block;
            border-radius: 10px;
            padding: 0px 8px;
            margin-left: 4px;
            margin-bottom: 2px;
            margin-top: 2px;
            font-size: 0.8rem;
            color: $color-primary;
            background-color: #cef1f4;
        }
    }
}

.dropdown-input {
    width: 100%;
}
</style>
